import { useParams, useNavigate } from 'react-router-dom';
import {useState, useRef} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'

function Book({ setBooking, convertISOto24H, add30MinAndConvert, isValidDate}) {
  const navigate = useNavigate();
  let { day,time, id } = useParams();

  const [meetingSelection, setMeetingSelection] = useState('us');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('')
  const [stand, setStand] = useState('')
  const [bookingInProgress, setBookingInProgress] = useState(false)

  function generateDates(start, end) {
    let currentDate = new Date(start.getTime());
    let dates = [];
    
    while(currentDate <= end) {
        dates.push(new Date(currentDate.getTime()).toISOString());
        currentDate.setMinutes(currentDate.getMinutes() + 30);
    }
    
    return dates;
}

let startEndPairs = [
    { start: new Date('2023-06-13T08:30:00+0100'), end: new Date('2023-06-13T17:00:00+0100') },
    { start: new Date('2023-06-14T08:30:00+0100'), end: new Date('2023-06-14T17:00:00+0100') },
    { start: new Date('2023-06-15T08:30:00+0100'), end: new Date('2023-06-15T15:30:00+0100') },
];

let allDates = startEndPairs.flatMap(pair => generateDates(pair.start, pair.end));

//console.log(JSON.stringify(allDates));

  let regex = /^\+?[\d\s\-\(\)]{7,}$/;

  const checkActive = () => {
    if (bookingInProgress) return false
    if (meetingSelection === 'them' && stand.length === 0) return false
    if (name.length >0 && regex.test(number)) return true
    return false
  }

  const handleOptionChange = (e) => {
      setMeetingSelection(e.target.value);
  }

  const book = async () => {
    const to = toast.loading("Booking...")
    setBookingInProgress(true)

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/slots`, {
        id,
        who: {
          name,
          number,
          stand: stand ? stand : 'A-E18' 
        }
  
      })
      setBookingInProgress(false)
      setBooking({time, day, name, number, stand: stand ? stand : 'A-E18'})
      toast.update(to,{render:"Booking success", autoClose:1000, type:'success', isLoading:false})
      navigate('/success')

    } catch (error) {
      console.log(error)
      setBookingInProgress(false)
      toast.update(to,{render:error.response.data.error, autoClose:1000, type:'error', isLoading:false})
    }
  
  }
  return (
    <>
    <div>
      {day} @ {convertISOto24H(time)}
    </div>
    <div style={{maxWidth:'680px',marginLeft:'auto', marginRight:'auto'}}>
      <div style={{marginTop:'15px',marginBottom:'15px'}}>
        <p style={{textAlign:'left',fontSize:'20px'}}>Name*</p>
       <input value={name} onChange={(e) => setName(e.target.value)} style={{border:'1px solid rgb(0, 46, 95)', height:'30px', padding:'10px', width: '100%'}}/> 
      </div>
    
      <div style={{marginTop:'15px',marginBottom:'15px'}}>
        <p style={{textAlign:'left',fontSize:'20px'}}>Phone Number*</p>
       <input value={number} onChange={(e) => setNumber(e.target.value)} style={{border:'1px solid rgb(0, 46, 95)',height:'30px', padding:'10px', width: '100%'}}/> 
      </div>

      <div style={{marginTop:'15px',marginBottom:'15px',textAlign:'left'}}>
        <p style={{textAlign:'left',fontSize:'20px'}}>Where should we meet?*</p>

        <div>
        <label style={{display:'flex'}}>
          <input
            type="radio"
            value="us"
            checked={meetingSelection === 'us'}
            onChange={handleOptionChange}
          />
          <p style={{marginLeft:'8px'}}>Our stand (A-E18)</p>
        </label>
      </div>
      <div>
        <label style={{display:'flex'}}>
          <input
            type="radio"
            value="them"
            checked={meetingSelection === 'them'}
            onChange={handleOptionChange}
          />
          <p style={{marginLeft:'8px'}}>Your stand</p>
        </label>
      </div>
      </div>
      {meetingSelection === 'them' && (
          <div style={{marginTop:'15px',marginBottom:'15px'}}>
           <p style={{textAlign:'left',fontSize:'20px'}}>Stand location*</p>
          <input value={stand} onChange={(e) => setStand(e.target.value)} style={{border:'1px solid rgb(0, 46, 95)', height:'30px', padding:'10px', width: '100%'}}/> 
         </div>
      )}
      <div onClick={() => {if(checkActive()) book()}} style={{cursor:'pointer', opacity:(checkActive()) ? 1 : 0.4, display:'flex', alignItems:'center', justifyContent:'center',height:'60px', backgroundColor:'rgb(0, 46, 95)', color:'white'}}>
        Book Slot
      </div>
    </div>
  </> 
   
  );
}

export default Book;
