import { useNavigate } from "react-router-dom";
import {useState, useRef} from 'react'

function Time({booking}) {
  const navigate = useNavigate();
  let t = new Date(booking.time)
  t.setHours(t.getHours() + 1)

  const bTime = t.toLocaleString('en-GB', { timeZone: 'UTC' })
  
  window.onpopstate = () => {
    navigate("/");
  }

  return (
    <>
    <div style={{display:'flex', flexDirection:'column', marginTop:'30px', alignItems:'center'}}>
    <svg style={{fill:'#80BA27', height:'40px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
      
        <p style={{margin:'5px', fontWeight:'700'}}>
        Booking Successful
          </p>
          <p >
            {bTime} at {booking.stand}
          </p>
        <p style={{marginTop:'20px', width:'200px'}}>
          Please contact <a style={{color:'#0073a6'}} href="tel:+447871252552"> <br/> +44 7442 451576 </a> <br/> for any queries
        </p>
    </div>
  </> 
   
  );
}

export default Time;
