import "./App.css"
import { Routes, Route, Outlet, Link } from "react-router-dom";
import {useEffect, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import nhlogo from "./assets/nh_logo.png"
import Book from "./pages/Book";
import Time from "./pages/Time";
import Success from "./pages/Success";
import Admin from './pages/Admin'
import {useLocation, useNavigate} from 'react-router-dom'


function App() {
  const [days,setDays] = useState(['Tuesday','Wednesday','Thursday'])
  const [currentDay, setCurrentDay] = useState(2)
  const [timeSlots, setTimeSlots] = useState([{time:'9:00',available:true},{time:'10:00',available:true},{time:'11:00',available:true},{time:'12:00',available:true},{time:'13:00',available:true},{time:'14:00',available:true},{time:'15:00',available:true},{time:'16:00',available:true},{time:'17:00',available:true},{time:'18:00',available:true},{time:'19:00',available:true},{time:'20:00',available:true}])
  const [booking, setBooking] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const getSlots = () => {
    fetch(`${process.env.REACT_APP_API_URL}/slots`)
    .then(response => response.json())
    .then(data => {
    //  console.log(data)
      setTimeSlots(data)
    })
  }


  function convertISOto24H(isoTime) {
    let dateObj = new Date(isoTime);
    let hours = dateObj.getUTCHours() + 1;
    let minutes = dateObj.getUTCMinutes();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes}`;
}

function add30MinAndConvert(isoTime) {
  // Create Date object and add 30 minutes
  let dateObj = new Date(isoTime);
  dateObj.setUTCMinutes(dateObj.getUTCMinutes() + 30);

  // Convert the new time to ISO string and then to 24H format
  return convertISOto24H(dateObj.toISOString());
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date);
}


  useEffect(() => {
    getSlots()
  },[location])
  return (
    <>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <img onClick={() => navigate('/')} style={{cursor:'pointer', width:'150px'}} src={nhlogo} alt="nhlogo" className="nhlogo"/>
      <div style={{textAlign:'center', color:'rgb(0, 46, 95)'}}>
        <h2>Book a Time</h2>
        
        <Routes>
        <Route index element={<Time booking={booking} setBooking={setBooking} convertISOto24H={convertISOto24H} add30MinAndConvert={add30MinAndConvert} isValidDate={isValidDate} days={days} setDays={setDays} currentDay={currentDay} setCurrentDay={setCurrentDay} timeSlots={timeSlots}/>}/>
        <Route path="/book/:day/time/:time/id/:id" element={<Book booking={booking} setBooking={setBooking}  convertISOto24H={convertISOto24H} add30MinAndConvert={add30MinAndConvert} days={days} setDays={setDays} currentDay={currentDay} setCurrentDay={setCurrentDay} timeSlots={timeSlots}/>}/>
        <Route path="/admin" element={<Admin  isValidDate={isValidDate}  booking={booking} setBooking={setBooking} convertISOto24H={convertISOto24H} add30MinAndConvert={add30MinAndConvert} days={days} setDays={setDays} currentDay={currentDay} setCurrentDay={setCurrentDay} timeSlots={timeSlots}/>}/>
        <Route path="/success" element={<Success booking={booking} setBooking={setBooking} convertISOto24H={convertISOto24H} add30MinAndConvert={add30MinAndConvert} days={days} setDays={setDays} currentDay={currentDay} setCurrentDay={setCurrentDay} timeSlots={timeSlots}/>}/>
        <Route path="*" element={<h1>404</h1>} />
      </Routes>

      </div>
  </> 
   
  );
}

export default App;