import { useNavigate } from "react-router-dom";
import {useState, useRef, useEffect} from 'react'

function Time({days, setCurrentDay, currentDay, timeSlots, convertISOto24H, add30MinAndConvert, isValidDate}) {
  const navigate = useNavigate();
  const [currentDayTimes, setCurrentDayTimes] = useState([])

  useEffect(() => {
      if (currentDay === 0) setCurrentDayTimes(timeSlots.filter((slot) => new Date(slot.time).getDay() === 2))
      if (currentDay === 1) setCurrentDayTimes(timeSlots.filter((slot) => new Date(slot.time).getDay() === 3))
      if (currentDay === 2) setCurrentDayTimes(timeSlots.filter((slot) => new Date(slot.time).getDay() === 4))

  },[currentDay])
  
  useEffect(() => {
    let t = 2;
    if (currentDay === 1) t =3;
    if (currentDay === 2) t =4;
    setCurrentDayTimes(timeSlots.filter((slot) => new Date(slot.time).getDay() === t))
  },[timeSlots])
  return ( 
    <>
    <div>
        <div style={{display:'flex', justifyContent:'center',margin:'25px'}}>
          {days.map((day,i)=>(
            <div key={day} onClick ={() => setCurrentDay(i)} style={{cursor:'pointer',margin:'5px', fontWeight: currentDay === i ? 'bold': '300' }}>
              {day}
            </div>
          ))}
        </div>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          {currentDayTimes.map((timeSlot,index)=>(
            <div key={timeSlot.time} onClick={() => {if(!timeSlot.booked && new Date() < new Date(timeSlot.time)) navigate(`/book/${days[currentDay]}/time/${timeSlot.time}/id/${timeSlot._id}`)}} style={{opacity: (timeSlot.booked || new Date() > new Date(timeSlot.time)) ? '0.3': '1', width:'100%',cursor:'pointer',display:'flex', alignItems:'center',justifyContent:'center', fontWeight:'500',color:'white',maxWidth:'680px', height:'60px', backgroundColor:'rgb(0, 46, 95)', margin:'0px 5px 16px 5px',zIndex:0,overflow:'hidden', transition:'box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s', border:'2px solid rgb(0, 46, 95)'}}>
              <p>
                {isValidDate(timeSlot.time) && (
                    <>
              {convertISOto24H(timeSlot.time)} - {add30MinAndConvert(timeSlot.time)}
                    
                    </>
                )}
              </p>
            </div>
          ))}
        </div>
    </div>
  </> 
   
  );
}

export default Time;
