import { useNavigate } from "react-router-dom";
import {useState, useRef, useEffect} from 'react'
import {toast} from 'react-toastify'


function Time({days, setCurrentDay, currentDay, convertISOto24H, add30MinAndConvert, isValidDate}) {
  const navigate = useNavigate();
  const [currentDayTimes, setCurrentDayTimes] = useState([])
  const [pwOk, setPwOk] = useState(false)
  const [pw,setPw] = useState('')

  const [timeSlots, setTimeSlots] = useState([])
  const [bookedOnlySlots, setBookedOnlySlots] = useState([])
  const [bookedOnly, setBookedOnly] = useState(false)

  const getSlots = () => {
    fetch(`${process.env.REACT_APP_API_URL}/slotsfull`)
    .then(response => response.json())
    .then(data => {
    //  console.log(data)
      setTimeSlots(data)
      setBookedOnlySlots(data.filter(slot => slot.booked))
    })
  }

  useEffect(() => {
    if (pwOk) {
    getSlots()
    }
  },[pwOk])

  const login = () => {
    if (pw === 'plantworxnhcap2023') return setPwOk(true)
    return toast.error('Incorrect password')
  }

  

  useEffect(() => {
      let slotsToFilter = bookedOnly ? bookedOnlySlots : timeSlots
      if (currentDay === 0) setCurrentDayTimes(slotsToFilter.filter((slot) => new Date(slot.time).getDay() === 2))
      if (currentDay === 1) setCurrentDayTimes(slotsToFilter.filter((slot) => new Date(slot.time).getDay() === 3))
      if (currentDay === 2) setCurrentDayTimes(slotsToFilter.filter((slot) => new Date(slot.time).getDay() === 4))

  },[currentDay])
  
  useEffect(() => {
    let t = 2;
    if (currentDay === 1) t =3;
    if (currentDay === 2) t =4;
    if (bookedOnly) setCurrentDayTimes(bookedOnlySlots.filter((slot) => new Date(slot.time).getDay() === t))
    if (!bookedOnly) setCurrentDayTimes(timeSlots.filter((slot) => new Date(slot.time).getDay() === t))
  },[timeSlots, bookedOnly])
  return (
    <>
    {!pwOk && (
      
      <div style={{maxWidth:'680px',marginLeft:'auto', marginRight:'auto'}}>
      <div style={{marginTop:'15px',marginBottom:'15px'}}>
        <p style={{textAlign:'left',fontSize:'20px'}}>Password</p>
       <input type="password" value={pw} onKeyDown={(e) => {
        if (e.key === 'Enter') return login()
       }} onChange={(e) => {
        setPw(e.target.value)
        }} style={{border:'1px solid rgb(0, 46, 95)', height:'30px', padding:'10px', width: '100%'}}/> 
      </div>
         <div onClick={() => login()} style={{cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center',height:'60px', backgroundColor:'rgb(0, 46, 95)', color:'white'}}>
         Login
       </div>
       </div>
    )}

    {pwOk && (
      <div>
        Admin
        <div style={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
          Booked Only
        <input style={{marginLeft:'5px'}} onChange={() => setBookedOnly(!bookedOnly)} type="checkbox" checked={bookedOnly}/>
        </div>
        <div style={{display:'flex', justifyContent:'center',margin:'25px'}}>
          {days.map((day,i)=>(
            <div key={day} onClick ={() => setCurrentDay(i)} style={{cursor:'pointer',margin:'5px', fontWeight: currentDay === i ? 'bold': '300' }}>
              {day}
            </div>
          ))}
        </div>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          {currentDayTimes.map((timeSlot,index)=>(
            <>
            <div key={timeSlot.time} style={{opacity: timeSlot.booked ? '0.3': '1', width:'100%',cursor:'pointer',display:'flex', alignItems:'center',justifyContent:'center', fontWeight:'500',color:'white',maxWidth:'680px', height:'60px', backgroundColor:'rgb(0, 46, 95)', margin: timeSlot.who ? '0px 5px 0px 5px' : '0px 5px 16px 5px', zIndex:0,overflow:'hidden', transition:'box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s', border:'2px solid rgb(0, 46, 95)'}}>
              <p>
                {isValidDate(timeSlot.time) && (
                    <>
              {convertISOto24H(timeSlot.time)} - {add30MinAndConvert(timeSlot.time)}
                    
                    </>
                )}
              </p>
            </div>
            {timeSlot.who && (
                  <div style={{border: '1px solid rgb(0, 46, 95)', borderTop:'none', margin: '0px 5px 16px 5px', width:'100%', maxWidth:'680px'}}>
                   <p>
                   {timeSlot.who.name}
                   </p>
                   <a href={`tel:${timeSlot.who.number}`}>
                   {timeSlot.who.number}
                   </a>
                   <p>
                   {timeSlot.who.stand}
                   </p>
                 </div>
            )}
       
            </>
          ))}
        </div>
    </div>
    )}
    
  </> 
   
  );
}

export default Time;
